import React from "react";
import { Link } from "@remix-run/react";

export default function Notice() {
  return (
    <div
      style={{ zIndex: 2 }}
      className="col bg-tertiary position-relative d-flex justify-content-center w-100"
    >
      <p className="mb-0 p-1">
        HeadLockr has finally launched 🚀, click{" "}
        <Link
          to="/pricing"
          className="fw-bold text-white"
        >
          {" "}
          here{" "}
        </Link>{" "}
        to get HeadLockr for free!
      </p>
    </div>
  );
}
