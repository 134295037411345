import { captureRemixErrorBoundaryError } from "@sentry/remix";
import type { LinksFunction } from "@remix-run/cloudflare";
import type { LoaderFunctionArgs } from "@remix-run/server-runtime";
import { useEffect, useState } from "react";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import { json } from "@remix-run/cloudflare";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ClientOnly } from "remix-utils/client-only";
import { ExternalScripts } from "remix-utils/external-scripts";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import NoticeBanner from "./components/utils/Notice";

// Assets
import logo from "./assets/logo/logo.png";

// Styles
import "./styles/scss/main.scss";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Credits from "./components/layout/Credits";

export const handle = {
  scripts: () => [
    {
      src: "https://code.jquery.com/jquery-3.7.1.slim.min.js",
      integrity: "sha256-kmHvs0B+OpCW5GVHUNjv9rOmY0IvSIRcf7zGUDTDQM8=",
      crossOrigin: "anonymous",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.min.js",
      crossOrigin: "anonymous",
    },
  ],
  breadcrumb: () => "Home",
};

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

export async function loader({ request, context }: LoaderFunctionArgs) {
  const userSession = await context.authenticator.isAuthenticated(request);

  return json({
    authContext: userSession,
    ENV: {
      RECAPTCHA_SITE_KEY: context.cloudflare.env.RECAPTCHA_SITE_KEY,
      GTM_ID: "GTM-MQRDDK6K" || context.cloudflare.env.GTM_ID,
    },
  });
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData("root") || {};
  const location = useLocation();

  const { ENV } = data || {};

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 1000 * 60 * 60 * 24,
            gcTime: 1000 * 60 * 60 * 24,
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
      })
  );

  useEffect(() => {
    ENV?.GTM_ID && addGtmScript(ENV?.GTM_ID);
  }, [ENV]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="bg-primary overflow-x-hidden">
        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript>
          <iframe
            title="Google Tag Manager"
            src="https://www.googletagmanager.com/ns.html?id=GTM-MQRDDK6K"
            height="0"
            width="0"
            style={{
              display: "none",
              visibility: "hidden",
            }}
          ></iframe>
        </noscript>
        {/* <!-- End Google Tag Manager (noscript) --> */}

        <QueryClientProvider client={queryClient}>
          {location.pathname.includes("checkout") ||
          location.pathname.includes("free-trial") ||
          location.pathname.includes("ebook/free-security-checklist") ||
          location.pathname.includes("download/ebooks/security") ? null : (
            <>
              <NoticeBanner />
              <Header />
            </>
          )}

          <div className="container">
            <div className="row">
              {/* <div className="col-xxl-12 px-lg-4 px-md-4 px-sm-2 py-lg-5 py-md-5 py-sm-0 px-1 d-flex justify-content-center">
                <CountdownTimer />
              </div> */}
            </div>

            <div className="row">
              <div className="col-xxl-12 px-lg-4 py-lg-5 py-md-5 py-sm-0">
                <GoogleReCaptchaProvider
                  reCaptchaKey={ENV?.RECAPTCHA_SITE_KEY}
                  language="nl"
                  scriptProps={{
                    async: true,
                    defer: true,
                  }}
                >
                  {children}
                </GoogleReCaptchaProvider>
              </div>
            </div>
          </div>
          {location.pathname.includes("checkout") ||
          location.pathname.includes("ebook") ? null : (
            <Footer />
          )}
          {location.pathname.includes("checkout") ||
          location.pathname.includes("ebook") ? null : (
            <div className="container bg-primary">
              <Credits />
            </div>
          )}
        </QueryClientProvider>

        <ScrollRestoration />
        <Scripts />
        <ExternalScripts />

        <ClientOnly>
          {() => (
            <script
              src="https://www.google.com/recaptcha/api.js?render=YOUR_SITE_KEY"
              async
              defer
            ></script>
          )}
        </ClientOnly>
      </body>
    </html>
  );
}

let gtmScriptAdded = false;

declare global {
  interface Window {
    [key: string]: object[];
  }
}

function addGtmScript(GTM_ID: string) {
  if (!GTM_ID || gtmScriptAdded) {
    return;
  }

  // Code copied from GTM console + added type annotations.
  (function (w: Window, d: Document, s: "script", l: string, i: string) {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement<"script">(s);
    const dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode?.insertBefore(j, f);
  })(window, document, "script", "dataLayer", GTM_ID);

  gtmScriptAdded = true;
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  console.log("Error =>", error);

  return (
    <div className="text-center bg-secondary p-4">
      <div className="logo mb-2">
        <img style={{ width: 200 }} src={logo} alt="404" />
      </div>
      <h1>Oops 😢</h1>
      <h2>
        {isRouteErrorResponse(error)
          ? `${error.status} ${error.statusText}`
          : error instanceof Error
          ? error.message
          : "Unknown Error"}
      </h2>

      <a href="/">
        <button className="btn btn-primary">Go back to home</button>
      </a>
    </div>
  );
};

export default function App() {
  return <Outlet />;
}
