import React from "react";

export default function Credits() {
  const year = new Date().getFullYear();

  return (
    <div className="container row py-1 ,,                                                                                  .">
      <div className="col-12">
        <p
          style={{
            fontSize: "12px",
          }}
          className="text-center text-white"
        >
          © {year} copyright Headlockr, all rights reserved
        </p>
      </div>
    </div>
  );
}
