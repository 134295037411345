import React from "react";
import Logo from "../../assets/logo/logo.png";
import Waving from "../../assets/layout/Waving.png";
import styles from "../*.module.scss";

export default function Footer() {
  const nav = [
    {
      category: "Product",
      subcategories: [
        { label: "Blog", link: "/blog" },
        {
          label: "Documentation",
          link: "https://docs.headlockr.io",
          target: "_blank",
          rel: "noopener noreferrer",
        },
        {
          label: "Roadmap",
          link: "https://headlockr.io/roadmap",
        },
      ],
    },
    {
      category: "Information",
      subcategories: [
        { label: "Support", link: "mailto:support@headlockr.io" },
        { label: "General Terms & Conditions", link: "/terms-and-conditions" },
        { label: "Privacy Policy", link: "/privacy-policy" },
        { label: "FAQ", link: "#faq" },
      ],
    },
    {
      category: "Contact",
      item: [{ label: "info@headlockr.io", link: "mailto:info@headlockr.io" }],
    },
  ];

  return (
    <footer className={`bg-tertiary text-light pt-2 ${styles.footer}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-2 mb-4 mb-md-0"></div>
          <div className="col-12 col-md-8 col-sm-12">
            <img
              src={Logo}
              alt="HeadLockr"
              className="img-fluid mb-2"
              style={{ width: "150px" }}
            />
            <div className="row">
              {nav.map((item, index) => (
                <div
                  key={index}
                  className="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0"
                >
                  <h5 className="text-uppercase">{item.category}</h5>
                  <ul className="list-unstyled">
                    {item.subcategories &&
                      item.subcategories.map((subitem, subindex) => (
                        <li key={subindex}>
                          <a
                            href={subitem.link}
                            target={subitem.target || ""}
                            rel={subitem.rel || ""}
                            className="text-light text-decoration-none"
                          >
                            {subitem.label}
                          </a>
                        </li>
                      ))}
                    {item.item &&
                      item.item.map((subitem, subindex) => (
                        <li key={subindex}>
                          <a
                            href={subitem.link}
                            className="text-light text-decoration-none"
                          >
                            {subitem.label}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 col-md-2 text-center">
            <img
              src={Waving}
              alt="Waving Character"
              className="img-fluid"
              style={{ width: "230px" }}
            />
          </div>
        </div>
      </div>
    </footer>
  );
}
