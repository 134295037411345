import React from "react";
import { Link } from "@remix-run/react";
import styles from "../*.module.scss";

export default function Nav() {
  const menu = [
    {
      name: "Pricing",
      link: "/#pricing",
    },
    {
      name: "Blog",
      link: "/blog",
    },
    {
      name: "Documentation",
      link: "https://docs.headlockr.io",
      target: "_blank",
      rel: "noopener noreferrer",
    },
    {
      name: "Roadmap",
      link: "/roadmap",
    },
  ];

  return (
    <>
      {" "}
      <ul className={`navbar-nav me-auto mb-2 mb-lg-0 ${styles.a}`}>
        {menu.map((item, index) => (
          <li key={index} className="nav-item">
            <Link
              className={`nav-link ${styles.link} mx-1`}
              aria-current="page"
              to={item.link}
              target={item.target || ""}
              rel={item.rel || ""}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
}
