import React, { useState, useEffect } from "react";
import Logo from "../../assets/logo/logo.png";
import { Link, Form } from "@remix-run/react";
import { motion } from "framer-motion";
import { useLoaderData } from "@remix-run/react";

// Components
import Nav from "./Nav";
import Offcanvas from "./Offcanvas";

export default function Header() {
  const [scroll, setScroll] = useState(false);
  const data = useLoaderData();
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <motion.nav
      initial={{
        background:
          "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)",
      }}
      animate={{
        background: scroll
          ? "linear-gradient(90deg, rgba(28, 32, 46, 0.9) 0%, rgba(28, 32, 46, 0.9) 100%)"
          : "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)",
        transition: { duration: 0.7, ease: "easeInOut" },
      }}
      style={{
        zIndex: 1000,
        position: "sticky",
        top: 0,
        width: "100%",
      }}
      className="navbar navbar-dark navbar-expand-lg px-2 py-1"
    >
      <div className="container col-xxl-12">
        <Link className="navbar-brand" to="/">
          <img
            src={Logo}
            alt="Logo"
            width="175"
            className="d-inline-block align-text-top"
          />
        </Link>
        {/* <!-- Mobile menu --> */}
        <div className="d-flex gap-1 align-items-center">
          {data?.authContext?.jwt && (
            <Form method="post" action="/auth/logout">
              <button
                className="btn btn-tertiary rounded-circle"
                style={{
                  width: "40px",
                  height: "40px",
                  padding: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                type="submit"
              >
                <img
                  src="https://img.icons8.com/?size=100&id=tV7P78fEpQCt&format=png&color=ffffff"
                  alt="avatar"
                  className="rounded-1"
                  style={{ width: "20px", height: "20px" }}
                />
              </button>
            </Form>
          )}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <Offcanvas />
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <Nav />
          <div className="d-flex gap-1 align-items-center">
            {!data?.authContext?.jwt ? (
              <Link
                to="/auth/login"
                type="submit"
                className="text-white text-decoration-none me-1"
              >
                Login
              </Link>
            ) : (
              <Form method="post" action="/auth/logout">
                <button
                  className="btn btn-tertiary rounded-circle"
                  style={{
                    width: "40px",
                    height: "40px",
                    padding: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  type="submit"
                >
                  <img
                    src="https://img.icons8.com/?size=100&id=tV7P78fEpQCt&format=png&color=ffffff"
                    alt="avatar"
                    className="rounded-1"
                    style={{ width: "20px", height: "20px" }}
                  />
                </button>
              </Form>
            )}

            {data?.authContext?.jwt ? (
              <Link
                to="/account"
                className="btn btn-tertiary rounded-circle"
                style={{
                  width: "40px",
                  height: "40px",
                  padding: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src="https://img.icons8.com/?size=100&id=eYwGmfzVOr0e&format=png&color=ffffff"
                  alt="avatar"
                  className="rounded-1"
                  style={{ width: "25px", height: "25px" }}
                />
              </Link>
            ) : (
              <Link to="/#pricing" className="btn btn-tertiary" type="submit">
                Start protecting now
              </Link>
            )}
          </div>
        </div>
      </div>
    </motion.nav>
  );
}
